//import Glide, { Images } from '@glidejs/glide/dist/glide.modular.esm';
import Glide from '@glidejs/glide';
import $ from 'jquery';

$(document).ready(function() {
    const logoSliders = document.querySelectorAll('.logo-slider');
    const logoSlidersConf = {
        type: 'carousel',
        autoplay: 2500,
        perView: 4,
        breakpoints: {
            800: {
                perView: 3,
            },
            500: {
                perView: 2,
            },
            450: {
                perView: 1,
            },
        },
        focusAt: 'center',
        hoverpause: true,
        peek: {
            before: 0,
            after: 0,
        },
        animationTimingFunc: 'ease',
        animationDuration: 4500,
    };

    logoSliders.forEach(item => {
        new Glide(item, logoSlidersConf).mount();
    });
});
